import React, { useEffect } from 'react'
import { PageProps, navigate } from 'gatsby'
import { useContext } from '~/hooks'

interface State {
  [key: string]: unknown
}

export default function NotFound({ location }: PageProps) {
  const { logout, setLoading } = useContext()

  useEffect(() => {
    setLoading(false)
    if ((location.state as State)?.logout) logout()
    else navigate('/')
  }, [])

  return <></>
}
